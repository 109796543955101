

import { Component, OnInit } from "@angular/core";
import { BaseStepComponent } from "../base-step.component";
import { FormBuilder, Validators } from "@angular/forms";
import { LeadService, LocalStorageService } from "../../services";
import { emailRegex } from "../../validators/validator";
import codes from 'country-calling-code';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'gsa-partner-contact-step',
    templateUrl: 'partner-contact-step.component.html',
    styleUrls: ['../../scss/workflows.scss', './partner-contact-step.component.scss'],
})
export class PartnerContactStepComponent extends BaseStepComponent implements OnInit {
    enabledCountryCodes:string[] = ['US', 'CA', 'IN', 'JP', 'AU', 'BR'];
    phoneCodes = [];

    constructor(private ls:LeadService, protected storage:LocalStorageService, protected fb:FormBuilder) {
        super(storage, fb);
    }

    ngOnInit(){
        this.stepName = "partnerContact";
        // Find the index of the element based on a condition
        const index = codes.findIndex(c => c.country === 'United States');
        if (index !== -1) {
            this.phoneCodes = codes.filter(c => c.country !== "United States")            
            this.phoneCodes.unshift(codes[index])
        } else {
            this.phoneCodes = codes;
        }

        this.form = this.fb.group({
            firstName: ['', [Validators.required ]],
            lastName: ['', [Validators.required ]],
            jobTitle: ['', [Validators.required ]],
            phoneCode: ['', Validators.required],
            phone: ['', [Validators.required, Validators.pattern(/^[+\d]?(?:[\d\-.()\s]*\d)$/)]],
            email: ['', [Validators.required, Validators.pattern(emailRegex)]],
        });
    }

    goNext() {
        if(!this.form.valid){
            Object.keys(this.form.controls).forEach(field => {
                const control = this.form.get(field);
                control?.markAsTouched({ onlySelf: true });
            });
            return;
        }
        super.goNext();
    }
}