import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validator to ensure at least one checkbox is checked or the custom field is filled in (and not equal to the default value).
 */
export const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export function atLeastOneCheckboxOrCustomFilled(defaultValue: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
        const controls = group.controls;
        let isValid = false;

        const customControl = group.get('custom');
        const otherControl = group.get('other');

        // Check if any of the checkboxes are checked (excluding 'other' and 'custom')
        const checkboxKeys = Object.keys(controls).filter(key => key !== 'custom' && key !== 'other');
        isValid = checkboxKeys.some(key => controls[key].value === true);

        // If no checkbox is checked, check if 'other' is selected and 'custom' is filled
        if (!isValid && otherControl && otherControl.value === true && customControl) {
            const customValue = customControl.value;
            if (customValue && customValue.trim() !== '' && customValue.trim() !== defaultValue) {
                isValid = true;
            }
        }

        return isValid ? null : { atLeastOne: true };
    };
}

export const atLeastOne = (validator: ValidatorFn, controls: string[] = null): ValidatorFn => {
    return (group: FormGroup): ValidationErrors | null => {
        if (!controls) {
          controls = Object.keys(group.controls);
        }
        const hasAtLeastOne = controls.some(key => {
          const control = group.get(key);
          let validated = validator(control);
          if (validated) console.log(key);
          return control.enabled && validator(control);
        });
        return (hasAtLeastOne) ? { atLeastOne: true } : null;
    };
};

export function notEmpty(control: FormControl): ValidationErrors | null {
    return control.value  && control.value != "Enter Reason" ? { required: true }: null ;
}

export function australianZipCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // Don't validate empty values to allow optional controls
    }
    if (control.value.length != 4) {
      return { 'invalidNumber': true };
    }
    const value = parseInt(control.value, 10);
    if (isNaN(value)) {
      return { 'invalidNumber': true };
    }
    if (value < 200 || value > 9999) {
      return { 'outOfRange': true };
    }
    
    return null;
  };
}

export function isValidURLValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if(/^(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(control.value)) {
      return null;
    }
    
    return { 'invalidURL': true };
  };
}