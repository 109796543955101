
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseStepComponent } from "../base-step.component";
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { LeadService, LocalStorageService } from "../../services";
import { atLeastOneCheckboxOrCustomFilled } from "../../validators/validator";
import { PartnerIndustry } from "../../models";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";

@Component({
    selector: 'gsa-partner-industries-services-step',
    templateUrl: 'partner-industries-services.component.html',
    styleUrls: ['../../scss/workflows.scss', './partner-industries-services.component.scss'],
})
export class PartnerIndustriesServicesStepComponent extends BaseStepComponent implements OnInit {

    industriesServed: string[];
    netServices: string[];
    netVendors: string[];
    @ViewChild('industriesServedOther') industriesServedOther:ElementRef;
    @ViewChild('netServicesOther') netServicesOther:ElementRef;
    @ViewChild('netVendorsOther') netVendorsOther:ElementRef;
    writingIndustriesServedReason:boolean = false;
    writingNetServicesReason:boolean = false;
    writingNetVendorsReason:boolean = false;

    industriesServedOptions: string[] = [
        // "Hospitality",
        // "MDU",
        // "Education",
        // "Smart Cities",
        // "Large venues",
        // "SMB",
        // "Federal",
    ];
    netServicesOptions: string [] = [
        "Sales and Fulfillment",
        "Design and Implementation (Professional Services)",
        "Reactive Support",
        "Managed Services, Proactive and Reactive Support",
    ];
    netVendorsOptions: string [] = [
        "Cisco",
        "Meraki",
        "Aruba",
        "Juniper",
        "Extreme",
        "Cambium",
        "Ubiquiti",
        "Huawei",
        "None of the Above",
    ];

    constructor(private ls: LeadService, protected storage: LocalStorageService, protected fb: FormBuilder) {
        super(storage, fb);
    }

    ngOnInit(){
        this.form = this.fb.group({});

        this.stepName = "industriesServices";

        this.ls.getPartnerIndustries().pipe(
            catchError(error => {
                // Handle the error here
                console.error('Error fetching partner industries:', error);
                // this.errorMessage = 'There was an error fetching the industries. Please try again later.';
                // Return an empty array or handle as necessary
                return of([]);
            })
        ).subscribe((response: PartnerIndustry[]) => {
            const partnerIndustries = response; 
            let newPartnerIndustries = []
            for (const partnerIndustry of partnerIndustries) {
                if (partnerIndustry.is_active) {
                    newPartnerIndustries = newPartnerIndustries.concat(partnerIndustry.value);
                }
            }
            this.industriesServedOptions = newPartnerIndustries;
            this.buildForm();
       });
    }

    buildForm() {
        this.form = this.fb.group({
            industriesServed: this.fb.group(
                this.initIndustriesServedControls(),
                { validators: atLeastOneCheckboxOrCustomFilled('Enter Industry') }
            ),
            netServices: this.fb.group(
                this.initNetServicesControls(),
                { validators: atLeastOneCheckboxOrCustomFilled('Enter Level of Service') }
            ),
            netVendors: this.fb.group(
                this.initNetVendorsControls(),
                { validators: atLeastOneCheckboxOrCustomFilled('Enter Another Vendor') }
            ),
        });
        this.customCheckBoxListener("industriesServed", "Enter Industry");
        this.customCheckBoxListener("netServices", "Enter Level of Service");
        this.customCheckBoxListener("netVendors", "Enter Another Vendor");
    }

    customCheckBoxListener(controlName: string, defaultValue:string ) {
        const form: AbstractControl = this.form.get(controlName);
        form.get('other').valueChanges.subscribe(value => {
            if (value == false){
                form.get('custom').setValue(defaultValue);
                this.disableAllReasons();
                return;
            } 
            this.clearCustom(controlName);
        });
    }

    initIndustriesServedControls(): { [key: string]: any } {
        const group = {}
        this.industriesServedOptions.forEach((industry) => {
            group[industry] = this.fb.control(false, Validators.required) // Set to false initially
        });
        group['other'] = this.fb.control(false, Validators.required);
        group['custom'] = this.fb.control("Enter Industry", Validators.required);
        return group
    }

    initNetServicesControls(): { [key: string]: any } {
        const group = {}
        this.netServicesOptions.forEach((netService) => {
            group[netService] = this.fb.control(false, Validators.required) // Set to false initially
        });
        group['other'] = this.fb.control(false, Validators.required);
        group['custom'] = this.fb.control("Enter Level of Service", Validators.required);
        return group
    }

    initNetVendorsControls(): { [key: string]: any } {
        const group = {}
        this.netVendorsOptions.forEach((netVendor) => {
            group[netVendor] = this.fb.control(false, Validators.required) // Set to false initially
        });
        group['other'] = this.fb.control(false, Validators.required);
        group['custom'] = this.fb.control("Enter Another Vendor", Validators.required);
        return group
    }

    disableAllReasons() {
        this.writingIndustriesServedReason= false;
        this.writingNetServicesReason= false;
        this.writingNetVendorsReason= false;
    }

    clearCustom(controlName:string){
        const form:AbstractControl = this.form.get(controlName);

        this.disableAllReasons();
        switch (controlName) {
        case 'industriesServed':
            this.writingIndustriesServedReason = true;
            break;
        case 'netServices':
            this.writingNetServicesReason= true;
            break;
        case 'netVendors':
            this.writingNetVendorsReason= true;
            break;
        default:
            console.log('Invalid. ' + controlName);
            break;
        }

        if (!form.get('other').value){
            form.get('other').setValue(true);
        }
        form.get('custom').setValue('');
    }
}