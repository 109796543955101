import { Component, OnInit } from "@angular/core";
import { BaseStepComponent } from "../base-step.component";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { LeadService, LocalStorageService } from "../../services";
import { australianZipCodeValidator, emailRegex } from "../../validators/validator";
import { Country, Region } from "../../models";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import codes from 'country-calling-code';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'gsa-contact-step',
    templateUrl: 'contact-step.component.html',
    styleUrls: ['../../scss/workflows.scss', './contact-step.component.scss'],
})
export class ContactStepComponent extends BaseStepComponent implements OnInit {

    enabledCountryCodes:string[] = ['US', 'CA', 'IN', 'JP', 'AU', 'BR'];
    zipCodeExamples = {
        'Canada': 'A1B 2C3',
        'United States': '12345',
        'Brazil': '12345-678',
        'Japan': '123-4567',
        'India': '123456',
        'Australia': '1234'
    };
    countries: Country[] = [];
    states: Region[] = [];
    phoneCodes = [];

    constructor(private ls:LeadService, protected storage:LocalStorageService, protected fb:FormBuilder) {
        super(storage, fb);
    }

    ngOnInit(){
        this.stepName = "contact";
        // Find the index of the element based on a condition
        const index = codes.findIndex(c => c.country === 'United States');
        if (index !== -1) {
            this.phoneCodes = codes.filter(c => c.country !== "United States");
            this.phoneCodes.unshift(codes[index]);
        } else {
            this.phoneCodes = codes;
        }

        this.form = this.fb.group({
            firstName: ['', [Validators.required ]],
            lastName: ['', [Validators.required ]],
            organization: ['', [Validators.required ]],
            email: ['', [Validators.required, Validators.pattern(emailRegex)]],
            phoneCode: ['', Validators.required],
            phone: ['', [Validators.required, Validators.pattern(/^[+\d]?(?:[\d\-.()\s]*\d)$/)]],
            country: [null, [Validators.required]],
            consent: [false, []],
            state: [null, Validators.required],
            zip: new FormControl({value: '', disabled: true}, [Validators.required]),
        });
        
        this.ls.getCountries().pipe(
            catchError(error => {
                // Handle the error here
                console.error('Error fetching countries:', error);
                // this.errorMessage = 'There was an error fetching the industries. Please try again later.';
                // Return an empty array or handle as necessary
                return of([]);
            })
        ).subscribe((response: Country[]) => {
            this.countries = response; 
            const unitedStates = this.countries.find(v => v.name == "United States");
            this.form.get('country').setValue(unitedStates);

       });

        const countryControl = this.form.get('country');
        const zipCodeControl = this.form.get('zip');
        countryControl.valueChanges.subscribe(country => {
            
            const countryName = country ? country.name.toLowerCase() : '';
            if (countryName === 'united states') {
                zipCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^\d{5}$/)  // U.S. zip codes must be exactly 5 digits
                ]);
            } else if (countryName === 'australia') {
                zipCodeControl.setValidators([
                    Validators.required,
                    australianZipCodeValidator()
                ]);
            } else if (countryName === 'brazil'){
                zipCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^\d{5}-\d{3}$/)  // Brazil zip codes must be exactly 00000-000
                ]);
            } else if (countryName === 'canada'){
                zipCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9]{3} [a-zA-Z0-9]{3}$/)  // K1A 24D 
                ]);
            } else if (countryName === 'india'){
                zipCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^\d{6}$/)  // India 6 digit
                ]);
            } else if (countryName === 'japan'){
                zipCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^\d{3}-\d{4}$/)  // Japan 123-4567
                ]);
            } else {
                zipCodeControl.setValidators([]);
            }
            zipCodeControl.updateValueAndValidity();

            if(!country || !country.region_required){
                this.form.get('state').disable();
                this.form.get('zip').disable();
                this.form.get('state').setValue('');
                this.form.get('zip').setValue('');
                return;
            }

            this.ls.getRegionsOfCountry(country.id).subscribe(states => {
                this.states = states;
                this.form.get('state').enable();
                this.form.get('zip').enable();

                this.form.get('state').setValue('');
                this.form.get('zip').setValue('');
            });
        });
    }
    
    goNext() {
        if(!this.form.valid){
            Object.keys(this.form.controls).forEach(field => {
                const control = this.form.get(field);
                control?.markAsTouched({ onlySelf: true });
            });
            return;
        }
        super.goNext();
    }
}