
import { Component } from "@angular/core";
import { BaseStepComponent } from "../base-step.component";
import { FormBuilder  } from "@angular/forms";
import { LeadService, LocalStorageService } from "../../services";
import {  Contact, LeadInput } from "../../models";

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'gsa-partner-submission-step',
    templateUrl: 'partner-submission-step.component.html',
    styleUrls: ['../../scss/workflows.scss', './partner-submission-step.component.scss'],
})
export class PartnerSubmissionStepComponent extends BaseStepComponent {

    success: number = 0; 
    constructor(private ls: LeadService, protected storage: LocalStorageService, protected fb: FormBuilder) {
        super(storage, fb);
    }
    
    parseCheckboxes(checkBoxObj:object) {
        console.log('checkBoxObj:', checkBoxObj);
        const result = Object.keys(checkBoxObj).filter(key => {
            console.log('key:', key);
            if (key == 'custom' || key == 'other') {
                return false;
            } else if (checkBoxObj[key] && checkBoxObj[key]) {
                return  true;
            }
            return false
        });

        // if no other is set, we want to save it as a string
        if (checkBoxObj['other'] === undefined) {
            return result[0];
        }

        // if other is set, we want to save it as array
        if (checkBoxObj['other']) {
            result.push(checkBoxObj['custom']);
        }

        return result;
    }

    onStepEnter(event): void {
        console.log('submission workflow:', this.workflow);
        const formData = this.storage.loadData(this.workflow);
        const comp = formData['partnerCompany'];
        const cont = formData['partnerContact'];
        const utm = this.storage.loadData(`${this.workflow}-utm`);

        const leadInput = new LeadInput();
        leadInput.workflow_type = this.workflow;
        leadInput.role = cont['jobTitle'];
        leadInput.utmCampaignMedium = utm['medium'];
        leadInput.utmCampaignSource = utm['source'];
        leadInput.partnerApplicationDetail = {
            'company_name': comp['companyName'],
            'company_address': comp['streetAddress'],
            'company_city': comp['city'],
            'company_country': comp['country']['name'],
            'company_country_id': comp['country']['id'],
            'company_state': comp['state'] ? comp['state']['name'] : '',
            'company_state_id': comp['state'] ? comp['state']['id'] : 0,
            'company_zipcode': comp['zip'],
            'industries_served': this.parseCheckboxes(formData['industriesServices']['industriesServed']),
            'geographies_served': this.parseCheckboxes(formData['helpSell']['geographiesServed']),
            'hear_about_us': formData['helpSell']['hearAboutUs'],
            'current_net_services': this.parseCheckboxes(formData['industriesServices']['netServices']),
            'current_net_vendors': this.parseCheckboxes(formData['industriesServices']['netVendors']),
            'ruckus_products': this.parseCheckboxes(formData['helpSell']['productInterests']),
            'add_value_help': this.parseCheckboxes(formData['helpSell']['ruckusHelp']),
            'num_employees': comp['noOfEmployees'],
            'company_revenue': comp['companyRevenue'],
            'express_consent': formData['partnerTerms']['consent'],
            'terms_signed_ts': Math.floor((new Date()).getTime() / 1000),
            'terms_signed_by': formData['partnerTerms']['signedBy'],
            'website': comp['website'],
        };
        
        const contact = new Contact();
        contact.name = `${cont['firstName']} ${cont['lastName']}`;
        contact.email = cont['email'];
        contact.phone = `${cont['phoneCode']}${cont['phone']}`;

        console.log('leadInput:', leadInput);
        console.log('contact:', contact);
        this.ls.postLead(
            leadInput,
            contact 
        ).subscribe({
            next: (res) => {
                console.log('Received response:', res);
                this.success = 1;
                localStorage.clear();
            },
            error: (error) => {
                console.error('Error:', error);
                this.success = -1;
            }
        });
    }
}