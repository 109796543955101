
import { Component, OnInit } from "@angular/core";
import { BaseStepComponent } from "../base-step.component";
import { Validators } from "@angular/forms";

@Component({
    selector: 'gsa-partner-terms-step',
    templateUrl: 'partner-terms.component.html',
    styleUrls: ['../../scss/workflows.scss', './partner-terms.component.scss'],
})
export class PartnerTermsStepComponent extends BaseStepComponent implements OnInit {

    today:string;
    ngOnInit(){
        this.stepName = "partnerTerms";
        let todayDate = new Date()
        this.today = todayDate.toISOString().split('T')[0]
        this.form = this.fb.group({ 
            signedBy: ['', [Validators.required, Validators.minLength(3)]],
            signDate: [this.today, [Validators.required, Validators.minLength(3)]],
            resellerAgreement: [false, [Validators.requiredTrue]],
            consent: [false, []],
        });
    }

    goNext() {
        if(!this.form.valid){
            Object.keys(this.form.controls).forEach(field => {
                const control = this.form.get(field);
                control?.markAsTouched({ onlySelf: true });
            });
            return;
        }
        super.goNext();
    }
}