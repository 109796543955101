
import { Component, OnInit } from "@angular/core";
import { BaseStepComponent } from "../base-step.component";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { LeadService, LocalStorageService } from "../../services";
import { Country, Region } from "../../models";
import { australianZipCodeValidator, isValidURLValidator } from "../../validators/validator";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'gsa-partner-company-step',
    templateUrl: 'partner-company-step.component.html',
    styleUrls: ['../../scss/workflows.scss', './partner-company-step.component.scss'],
})
export class PartnerCompanyStepComponent extends BaseStepComponent implements OnInit {
    noOfEmployeesOptions = [
        'Less than 50',
        '50-100',
        '101-250',
        'Over 250',
    ]
    // noOfEmployeesOptions = [
    //     ['Less than 50', 50],
    //     ['50-100', 100],
    //     ['101-250', 250],
    //     ['Over 250', 251],
    // ];
    companyRevenueOptions = [
        ['0-5 million', 5000000],
        ['5-10 million', 10000000],
        ['10-25 million', 25000000],
        ['over 25 million', 26000000],
    ];
    postalCodeExamples = {
        'Canada': 'A1B 2C3',
        'United States': '12345',
        'Brazil': '12345-678',
        'Japan': '123-4567',
        'India': '123456',
        'Australia': '1234'
    };
    countries: Country[] = [];
    states: Region[] = [];
    hasDbaNames: boolean = false;

    success: number = 0; 
    constructor(private ls: LeadService, protected storage: LocalStorageService, protected fb: FormBuilder) {
        super(storage, fb);
    }

    ngOnInit(){
        this.stepName = "partnerCompany";

        this.form = this.fb.group({
            companyName: ['', [Validators.required]],
            noOfEmployees: [null, [Validators.required]],
            companyRevenue: [null, [Validators.required]],
            website: ['', [ Validators.required, isValidURLValidator()]],
            streetAddress: ['', Validators.required],
            country: [null, [Validators.required]],
            state:  [null, Validators.required],
            city: ['', [Validators.required]],
            zip: new FormControl({value: '', disabled: true}, [Validators.required]),
        });
        
        this.ls.getCountries().pipe(
            catchError(error => {
                // Handle the error here
                console.error('Error fetching countries:', error);
                // this.errorMessage = 'There was an error fetching the industries. Please try again later.';
                // Return an empty array or handle as necessary
                return of([]);
            })
        ).subscribe((response: Country[]) => {
            this.countries = response; 
            const unitedStates = this.countries.find(v => v.name == "United States");
            this.form.get('country').setValue(unitedStates);
       });

        const countryControl = this.form.get('country');
        const postalCodeControl = this.form.get('zip');
        countryControl.valueChanges.subscribe(country => {
            
            const countryName = country ? country.name.toLowerCase() : '';
            if (countryName === 'united states') {
                postalCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^\d{5}$/)  // U.S. postal codes must be exactly 5 digits
                ]);
            } else if (countryName === 'australia') {
                postalCodeControl.setValidators([
                    Validators.required,
                    australianZipCodeValidator()
                ]);
            } else if (countryName === 'brazil'){
                postalCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^\d{5}-\d{3}$/)  // Brazil postal codes must be exactly 00000-000
                ]);
            } else if (countryName === 'canada'){
                postalCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9]{3} [a-zA-Z0-9]{3}$/)  // K1A 24D 
                ]);
            } else if (countryName === 'india'){
                postalCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^\d{6}$/)  // India 6 digit
                ]);
            } else if (countryName === 'japan'){
                postalCodeControl.setValidators([
                    Validators.required,
                    Validators.pattern(/^\d{3}-\d{4}$/)  // Japan 123-4567
                ]);
            } else {
                postalCodeControl.setValidators([]);
            }
            postalCodeControl.updateValueAndValidity();

            if(!country || !country.region_required){

                this.form.get('state').disable();
                this.form.get('zip').disable();
                this.form.get('state').setValue('');
                this.form.get('zip').setValue('');
                return;
            }

            this.ls.getRegionsOfCountry(country.id).subscribe(states => {
                this.states = states;
                this.form.get('state').enable();
                this.form.get('zip').enable();

                this.form.get('state').setValue('');
                this.form.get('zip').setValue('');
            });
        });
    }

    goNext() {
        console.log(this.form);
        if(!this.form.valid){
            Object.keys(this.form.controls).forEach(field => {
                const control = this.form.get(field);
                control?.markAsTouched({ onlySelf: true });
            });
            return;
        }
        super.goNext();
    }
}